* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "msb";
  src: local("msb"), url("../src/Fonts/Mulish-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "mb";
  src: local("mb"), url("../src/Fonts/Mulish-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "mm";
  src: local("mm"), url("../src/Fonts/Mulish-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "mr";
  src: local("mr"), url("../src/Fonts/Mulish-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "meb";
  src: local("mr"), url("../src/Fonts/Mulish-ExtraBold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Landing Nav Bar */
.div_overflow {
  overflow: hidden;
}

.bul_cendrol_log {
  width: 111px;
  height: 39.22px;
}

.bul_nav_bul_mail_icons {
  color: #fdc315;
}
.nav_whatapp {
  color: #00e676;
}

.bul_land_nav_ul li a {
  display: flex;
  gap: 10px;
  text-decoration: none;
  font-family: "mb";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.bul_land_nav_ul > li + li {
  border-left: solid 1px #0a0a0a;
}

.btn_get_free_quote {
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 2px;
  font-family: "msb";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.btn_get_free_quote:focus {
  box-shadow: none;
}
/* NAVBAR COMPLETE CSS */
.navbar-brand img {
  width: 150px;
}
ul.topInfo > li {
  font-size: 20px;
  line-height: 1;
  padding: 0px 25px;
  display: inline-block;
}
ul.topInfo > li > a {
  color: #000;
  display: block;
}
ul.topInfo > li > a > i {
  color: #fdc315;
  margin-right: 5px;
}
.siteHeader .navbar {
  padding: 0px;
  left: 0px;
  width: 100%;
  top: 0px;
  z-index: 1000;
  position: absolute;
  transition: ease-in-out 0.5s;
}
.siteHeaderInner .navbar {
  background: #fff;
  position: initial;
  top: 0px;
}
.siteHeaderInner .navbar ul.navbar-nav li a.nav-link {
  color: #9f9898;
}
.siteHeaderInner .topBar {
  border-bottom: solid 1px #e9e9e9;
}
.siteHeader {
  padding: 25px 0px;
}
.siteHeadernew .sticky-wrapper {
  height: auto !important;
}
.siteHeadernew.siteHeader .navbar {
  position: relative;
}
ul.bul_socialIcons {
  font-size: 20px;
  list-style: none;
}
ul.bul_socialIcons li {
  display: inline-block;
}
ul.bul_socialIcons li a {
  color: #000;
}
ul.topInfo > li > a:hover {
  text-decoration: underline;
}
ul.topInfo > li > a {
  color: #000;
  display: block;
  text-decoration: none;
}
.whatsap_icon {
  color: #33b315;
  font-size: 23px;
}
.bul_mail_icon {
  color: #fdc315;
  margin-right: 5px;
}
/*  */
/* navbar */
.img_logo {
  width: 7rem;
}
.cost_cal_btn {
  background: linear-gradient(92.68deg, #ffe185 -18.4%, #f7ba00 113.86%);
  border-radius: 5px;
  border: none;
  padding: 10px;
}
.top_menu_search {
  border: none;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  background-color: #f4f4f4;
  font-family: mr;
  font-size: 12px;
  line-height: 14px;
  color: #757575;
}

.top_menu_search:focus {
  outline: none;
}

.profile_img {
  width: 36px;
  height: 36px;
  /* border: 1.2375px solid #fcc314; */
}
.bul_build_nav {
  background: #fff;
  padding: 1rem 3rem;
}
.bul_cal_btn {
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 2px;
  border: none;
  padding: 15px 23px;
  font-family: "msb";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  justify-content: center;
}
/* Footer */
.bul_footer {
  color: #666;
  overflow-x: hidden;
  font-size: 18px;
  font-weight: 500;
  background-color: #fff;
}
.bul_rowMainFooter {
  padding: 4s0px;
}

.bul_main_align {
  padding: 0px 50px;
}
.bul_boxCopyright {
  text-align: center;
  padding: 18px 0px;
  background-color: #ececec;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.bul_boxCopyright p {
  font-family: "msb";
}

.bul_footerLogo {
  width: 150px;
  margin-bottom: 30px;
}
.bul_footerLogo img {
  width: 100%;
}
.bul_rowMainFooter h4 {
  text-transform: none;
  padding-bottom: 12px;
  font-family: "msg";
  position: relative;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}
.bul_rowMainFooter h4:after {
  width: 40px;
  height: 4px;
  bottom: 0px;
  left: 0px;
  content: "";
  position: absolute;
  background-color: #fdc315;
}

ul.bul_socialIcons li a:hover {
  color: #fdc315;
}
ul.bul_socialIcons {
  font-size: 20px;
  list-style: none;
  padding-left: 0px;
}
ul.bul_socialIcons li {
  display: inline-block;
}
ul.bul_socialIcons li + li {
  margin-left: 20px;
}
ul.bul_socialIcons li a {
  color: #000;
}

ul.bul_listContactInfo {
  list-style: none;
  padding-left: 0px;
}
ul.bul_listContactInfo li:first-child {
  border-top: none;
}
ul.bul_listContactInfo li {
  position: relative;
  padding-left: 33px;
  padding-top: 18px;
  margin-top: 10px;
  border-top: solid 1px #cbcbcb;
}
ul.bul_listContactInfo li a {
  text-decoration: none;
  font-family: "msb";
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
ul.bul_listContactInfo li a:hover {
  color: #fdc315;
}

.bul_land_li_icons {
  position: absolute;
  left: 0px;
  color: #fdc315;
  top: 23px;
}
.mob_cal_btn {
  display: none;
  background: #fdc315;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-family: msb;
}
.bul_errors {
  color: red !important;
}
.bul_otp_sent,
.bul_otp_worong,
.bul_errors {
  font-family: "msb";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}

.bul_otp_sent {
  color: #3add5e;
}

.bul_otp_worong {
  color: red;
}
#signup {
  display: none;
}
.bul_popup_form_btn {
  background: #fdc315;
  border-radius: 2px;
  font-family: "msb";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #221e1f;
  border: none;
  width: 100%;
  padding: 14px;
}
.get_free_quote_btn {
  /* border: 1px solid #000; */
  background-color: #fff;
}
.bul_thank_subheading,
.bul_thank_subheading_page {
  font-family: "msb";
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #969696;
}

.bul_thank_subheading {
  font-size: 15px;
}

.bul_thank_subheading_page {
  font-size: 18px;
}
/* listing */
.main_div_cards {
  background: #f6f6f6;
  padding: 2.5rem 3.7rem;
}
.TabSec {
  display: flex;
  /* width: 100%; */
  flex-direction: row;
}
.activeTab {
  color: #000;
  font-family: msb;
  cursor: pointer;
  border-bottom: 1px solid #f7b314;
  font-size: 15px;
  /* padding: 4px 15px; */
  padding: 4px 40px;
}
.tabs {
  cursor: pointer;
  font-size: 13px;
  /* padding: 4px 5px; */
  padding: 4px 40px;
  font-family: mr;
  color: #909090;
  border-bottom: 2px solid #e2e2e2;
}
.project_list_title {
  font-family: meb;
  color: #0e0e0e;
}
.contentHomeDesign {
  background: #ffffff;
  border-radius: 2px;
  margin-bottom: 40px;
}
.contentHomeDesign .imgDesign img {
  /* border-radius: 10px; */
  /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
  height: 230px;
  object-fit: cover;
  width: 100%;
}
.contentHomeDesign:hover {
  transition: 0.5s ease-in-out;
  transform: scale(1.05);
}
.cendrolproject_sqft {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 1.07135px;
  padding: 11px 10px 11px 9px;
}
.cendrolproject__sqft_text {
  font-family: msb;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.bs_row {
  justify-content: space-between;
}
.designDetail {
  padding: 14px;
}
.name_area_sec {
  text-align: initial;
}
.project_name {
  font-family: meb;
  font-size: 18px;
}
.cendrolproject_location {
  color: #909090;
  font-family: mr;
  font-size: 13px;
}
.card_image {
  position: relative;
}
.project_tag_trending {
  position: absolute;
  top: 15px;
  right: 15px;
  background: linear-gradient(96.38deg, #ff5a00 -6.7%, #ff5d83 96.65%);
  border-radius: 16px;
  color: #fff;
  font-family: msb;
  padding: 5px 15px;
}
.project_tag_details {
  background: linear-gradient(96.38deg, #ff5a00 -6.7%, #ff5d83 96.65%);
  border-radius: 16px;
  color: #fff;
  font-family: msb;
  padding: 5px 15px;
}
.project_tag_top {
  position: absolute;
  top: 15px;
  right: 15px;
  background: linear-gradient(97.95deg, #9e5eff -74.45%, #1cfad8 108.08%);
  border-radius: 16px;
  color: #fff;
  font-family: msb;
  padding: 5px 15px;
}
a {
  text-decoration: none;
  color: #000 !important;
}

.navigation_section {
  margin-top: 10px;
}
.project_details_section {
  margin-top: 20px;
  padding: 26px;
  background: #ffffff;
  border-radius: 2px;
}
.main_div_cards_detailspage {
  background: #f6f6f6;
  padding: 1rem 8rem;
}
.containerprojectBody {
  width: 82%;
  margin: auto;
  background-color: #ffffff;
  padding: 2%;
}
.projectCustomer {
  font-family: mb;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #221e1f;
}
.shareBTN {
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 2px;
  padding: 14px 20px;
  font-family: msb;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 14px;
  cursor: pointer;
}
.shareble_btn {
  background: #f6f6f6;
  border-radius: 2px;
  font-family: msb;
  padding: 14px 14px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
}
.locationProject {
  color: #848484;
  font-family: mm;
  font-size: 16px;
}
.Overviewproject {
  background-color: #f6f6f6;
  color: #000;
}
.Overviewtext {
  padding: 20px;
  font-family: msb;
  font-size: 17px;
}
.Builtareaproject {
  font-family: msb;
  font-weight: 500;
  font-size: 14px;
  color: #848484;
  line-height: 19px;
}
.Builtareaprojectsub {
  font-family: mb;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0e0e0e;
}
.proj_descrip {
  padding: 15px;
}
.readmore_txt {
  color: #f7b314;
  cursor: pointer;
  text-align: justify;
}
/* .form_section {
  background: url("./Assets/Svg/contact\ us.svg");

  border-radius: 2px;
  color: #fff;
  padding: 18px;
} */
.form_section {
  height: 45vh;
  background-color: #242424 !important;
  /* background: url("./Assets/Svg/contact\ us.svg") no-repeat no-repeat; */
  border-radius: 2px;
  color: #fff;
  padding: 18px;
  background-repeat: no-repeat;
}

.form_section p {
  font-family: mb;
  line-height: 2.5rem;
  font-size: 32px;
}
.form_section span {
  padding-top: 10px;
  color: #cccccc;
}
.plus_91_div {
  background: rgb(255, 229, 150);
  display: flex;
  align-items: center;
  color: #000;
  padding: 5px;
  font-family: mr;
  font-size: 14px;
}
.sunscribe_div {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 5px;
  font-size: 14px;
  display: flex;
  width: 90%;
}
.subscribe_verify_btn {
  font-family: msb;
  padding: 5px 14px;
  border: none;
  background: #fdc315;
  border-radius: 2px;
  font-size: 13px;
}
.subscribe_textbox {
  font-family: mr;
  width: inherit;
  font-size: 14px;
  border: none;
  padding: 8px;
  background-color: #fff;
}
.mob_no {
  font-size: 16px !important;
  font-family: msb !important;
}
.subscribe_textbox:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Carousel css */
/* grid start */
.parentGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  height: 488px;
}
.parentGrid1 {
  grid-area: 1 / 1 / 4 / 3;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.parentGrid2 {
  grid-area: 1 / 3 / 3 / 5;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.parentGrid3 {
  grid-area: 3 / 3 / 4 / 4;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.parentGrid4 {
  position: relative;
  grid-area: 3 / 4 / 4 / 5;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
.CoverImg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  color: #ffffff;
}
.OTP_input:focus {
  outline: none !important;
}
.resend_otp {
  color: #fcc314 !important;
  cursor: pointer;
  text-decoration: underline;
  /* pointer-events: none; */
}
.thank_you_sec {
  background: #e6ffe8;
  border-radius: 2px;
  padding: 25px;
}
.thankyou p {
  font-family: meb;
  font-size: 32px;
  line-height: 2.5rem;
}
.thankyou span {
  color: #909090;
  font-size: 20px;
  font-family: mr;
}
.otp_sent {
  margin-bottom: 3px;
  /* margin-left: 13px !important; */
  font-family: msb;
  font-size: 12px;
  color: rgb(135, 231, 135);
}
.otp_wrong {
  margin-bottom: 3px;
  /* margin-left: 13px !important; */
  font-family: msb;
  font-size: 12px;
  color: rgb(255, 57, 22);
}
.outoffimg {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.Sharepop {
  font-family: mb;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #151414;
}
.linkcopy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f6f6f6;
  border-radius: 2px;
  padding: 15px;
  width: 100%;
}
.copyLink {
  cursor: pointer;
  padding: 8px 5px;
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 2px;
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  color: #000000;
}
.linkcopytext {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  /* identical to box height */

  color: #000000;
}
.or {
  font-family: msb;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #909090;
  margin-top: 10px;
}
.btnSharelink {
  background: #59d263;
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 10px 15px;
}
.link_copied {
  color: #59d263;
  font-size: 14px;
  font-family: "msb";
}
/* modal css */

.bul_thankyou_heading,
.bul_thankyou_heading_page {
  font-family: "msb";
  font-style: normal;
  font-weight: 800;
  text-align: center;
  color: #0a0a0a;
}
/* Get free quote */
.bul_keep_browsing {
  border: none;
  background: #fdc315;
  border-radius: 1px;
  padding: 15px 30px;
  font-family: "msb";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.bul_thankyou_heading {
  font-size: 22px;
  line-height: 36px;
}
.bul_modal_head_txt {
  font-family: "meb";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}
.bul_modal_sub_txt,
.bul_user_form_label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}
.bul_user_form_label {
  color: #0a0a0a;
  font-family: mm;
  padding-bottom: 10px;
}
.bul_user_form_fileds {
  background: #f2f2f2 !important;
  border: none;
  border-radius: 2px;
  color: #909090;
  font-family: mr;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 15px;
}
.bul_modal_sub_txt {
  color: #919191;
  font-family: mm;
}
.bul_number_edit_91 {
  align-items: center;
  background: #ffe596;
  border-radius: 1px 0 0 1px;
  color: #000;
  display: flex;
  font-family: msb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

.bul_btn_verify,
.bul_keep_browsing {
  background: #fdc315;
  color: #000;
  font-family: msb;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.bul_btn_verify {
  border: none;
  border-radius: 2px;
  font-size: 13px;
  line-height: 18px;
  padding: 8px 22px;
}
.caro_div {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  position: relative;
}
.share_modal {
  background: #fff;
  width: 40%;
  padding: 20px;
}
.sharables_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_heading_1 {
  font-family: meb !important;
  line-height: 2.5rem !important;
  font-size: 32px !important;
}
.form_heading_2 {
  color: #cccccc;
  font-size: 20px;
  font-family: mr !important;
}
.overview_icons {
  padding: 18px 18px 32px 18px;
}
.carousel_img_div {
  height: 80vh;
}
.caro_img {
  height: inherit;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .carousel_img_div {
    height: auto;
  }
  .overview_icons {
    padding: 5px;
  }
  .project_details_section {
    padding: 15px;
  }
  .share_modal {
    width: 95%;
    background: #fff;
  }
  .copyLink {
    cursor: pointer;
    padding: 6px 5px;
    background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
    border-radius: 2px;
    font-family: msb;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 19px;
    color: #000000;
  }
  .form_heading_1 {
    font-size: 26px !important;
  }
  .sharables_btn {
    justify-content: space-between;
  }
  .form_section {
    height: auto;
  }
  .linkcopytext {
    font-size: 11px;
  }
  .bul_navbar_li_div {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .projec_header_section {
    flex-direction: column !important;
  }
  .main_div_cards_detailspage {
    padding: 1rem 0.5rem !important;
  }
  .bul_cal_btn {
    width: 100%;
  }
  .land_nav_toggle {
    padding-top: 2rem;
  }
  .main_div_cards {
    padding: 2.5rem 1rem;
  }
  .tabs {
    padding: 4px 5px;
  }
  .activeTab {
    padding: 4px 15px;
  }
  .bul_sliding_comp {
    margin-top: 20% !important;
  }
  .bul_navbar_container {
    /* padding: 1rem 1rem; */
  }
  .bul_build_nav {
    padding: 1rem 1rem !important;
  }
  .parentGrid1 {
    grid-area: 1 / 5 / 1 / 1;
  }
  .parentGrid2 {
    grid-area: 2 / 1 / 3 / 5;
  }
  .parentGrid3 {
    grid-area: 3 / 3 / 4 / 1;
  }
  .parentGrid4 {
    grid-area: 3 / 3 / 3 / 5;
  }
  .Builtareaprojectsub {
    font-size: 10px;
  }
  .Builtareaproject {
    font-size: 10px;
  }
  .shareble_btn {
    font-size: 12.5px;
  }
  .shareBTN {
    padding: 14px 14px;
    font-size: 12.5px;
    cursor: pointer;
  }
  .break {
    display: none;
  }
  .carousel .slide {
    min-width: 75%;
    display: flex !important;
    align-items: center !important;
    align-self: center !important;
  }
  .carousel .slide img {
    height: 245px !important;
  }
  .projectCustomer {
    font-size: 18px;
  }
  .cendrolproject_leftarrow {
    left: 20px !important;
  }
  .cendrolproject_rightarrow {
    right: 20px !important;
  }
  .caro_div {
    margin-top: 45%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .main_div_cards_detailspage {
    padding: 1rem 1rem !important;
  }
}
