/* Landing Nav Bar */
.div_overflow {
  overflow: hidden;
}

.bul_cendrol_log {
  width: 111px;
  height: 39.22px;
}

.bul_nav_bul_mail_icons {
  color: #fdc315;
}
.nav_whatapp {
  color: #00e676;
}

.bul_land_nav_ul li a {
  display: flex;
  gap: 10px;
  text-decoration: none;
  font-family: "mb";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.bul_land_nav_ul > li + li {
  border-left: solid 1px #0a0a0a;
}

.btn_get_free_quote {
  background: linear-gradient(92.68deg, #ffe185 -18.4%, #f7ba00 113.86%);
  border-radius: 5px;
  font-family: "FTPD";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.btn_get_free_quote:focus {
  box-shadow: none;
}
/* NAVBAR COMPLETE CSS */
.navbar-brand img {
  width: 150px;
}
ul.topInfo > li {
  font-size: 20px;
  line-height: 1;
  padding: 0px 25px;
  display: inline-block;
}
ul.topInfo > li > a {
  color: #000;
  display: block;
}
ul.topInfo > li > a > i {
  color: #fdc315;
  margin-right: 5px;
}
.siteHeader .navbar {
  padding: 0px;
  left: 0px;
  width: 100%;
  top: 0px;
  z-index: 1000;
  position: absolute;
  transition: ease-in-out 0.5s;
}
.siteHeaderInner .navbar {
  background: #fff;
  position: initial;
  top: 0px;
}
.siteHeaderInner .navbar ul.navbar-nav li a.nav-link {
  color: #9f9898;
}
.siteHeaderInner .topBar {
  border-bottom: solid 1px #e9e9e9;
}
.siteHeader {
  padding: 25px 0px;
}
.siteHeadernew .sticky-wrapper {
  height: auto !important;
}
.siteHeadernew.siteHeader .navbar {
  position: relative;
}
ul.bul_socialIcons {
  font-size: 20px;
  list-style: none;
}
ul.bul_socialIcons li {
  display: inline-block;
}
ul.bul_socialIcons li a {
  color: #000;
}
ul.topInfo > li > a:hover {
  text-decoration: underline;
}
ul.topInfo > li > a {
  color: #000;
  display: block;
  text-decoration: none;
}
.whatsap_icon {
  color: #33b315;
  font-size: 23px;
}
.bul_mail_icon {
  color: #fdc315;
  margin-right: 5px;
}
/*  */
/* navbar */
.img_logo {
  width: 7rem;
}
.cost_cal_btn {
  background: linear-gradient(92.68deg, #ffe185 -18.4%, #f7ba00 113.86%);
  border-radius: 5px;
  border: none;
  padding: 10px;
}
.top_menu_search {
  border: none;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  background-color: #f4f4f4;
  font-family: GM;
  font-size: 12px;
  line-height: 14px;
  color: #757575;
}

.top_menu_search:focus {
  outline: none;
}

.profile_img {
  width: 36px;
  height: 36px;
  /* border: 1.2375px solid #fcc314; */
}
.bul_build_nav {
  background: #fff;
  padding: 1rem 3rem;
}
.bul_sliding_comp {
  margin-top: 6%;
  position: relative;
}
